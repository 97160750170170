#darkMode {
  float: right;
  background: transparent url("../img/darkMode/moon.png") no-repeat center;
  background-size: 30px;
  width: 45px;
  height: 45px;
  filter: grayscale(100%);
  border: none;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
  &:hover,
  &:focus {
    filter: none;
    background-color: black;
    cursor: pointer;
    &.clicked {
      filter: none !important;
      background-color: black;
    }
  }
}
