@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@import "darkMode";
@import "pet.scss";

:root {
  --blue: rgb(26, 57, 87);
  --white: rgb(255, 255, 255);
  --petPosition: 0;
}

body {
  &.light {
    --background-color: var(--white);
    --text-color: var(--blue);
    --font-weight: 400;
    // --image: url("../img/darkMode/day.jpg");
    --image: url("");
  }
  &.dark {
    --background-color: var(--blue);
    --text-color: var(--white);
    --font-weight: 500;
    --image: url("../img/darkMode/night2.jpg");
  }
  font-family: "Press Start 2P", cursive;
  background: var(--background-color);
  color: var(--text-color);
  font-weight: var(--font-weight);
  transition: background 0.3s ease-in-out, color 0.6s ease-in-out;
  background-image: var(--image);
}

h1 {
  text-align: center;
}
