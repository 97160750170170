.pet__footer {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  height: 50px;
  width: 100%;
  //   background-color: cadetblue;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #5d852f;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 3px;
    width: 100%;
    height: 3px;
    background-color: #87d72b;
  }
}

.dog__container {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 4px;
  // animation: walkingAnimation 12s linear infinite;
  left: var(--petPosition);
  cursor: pointer;
  .dog {
    width: 60px;
    height: 48px;
    //background-image: url(https://raw.githubusercontent.com/gokhanozt/gokhanozt/master/assets/dogs1.png);
    //animation: dogAnimation 0.7s steps(2) infinite;
    cursor: pointer;
    filter: drop-shadow(0px -1px 7px rgba(0, 0, 0, 0.8));
  }
  &.sleep {
    .dog {
      background-image: url(https://raw.githubusercontent.com/gokhanozt/gokhanozt/master/assets/dog_sleep2.png);
      animation: dogAnimation 1.4s steps(2) infinite;
    }
  }
  &.walk {
    animation: walkingAnimation 12s linear infinite;
    .dog {
      background-image: url(https://raw.githubusercontent.com/gokhanozt/gokhanozt/master/assets/dogs1.png);
      animation: dogAnimation 0.7s steps(2) infinite;
    }
  }
  &.stay {
    // animation: walkingAnimation 12s linear infinite;
    .dog {
      background-image: url(https://raw.githubusercontent.com/gokhanozt/gokhanozt/master/assets/dog-stay.png);
      animation: dogAnimation 0.7s steps(2) infinite;
    }
  }
  &.backward {
    animation: backwardWalkingAnimation 12s linear infinite;
    .dog {
      // -webkit-transform: scaleX(-1);
      // transform: scaleX(-1);
      background-image: url(https://raw.githubusercontent.com/gokhanozt/gokhanozt/master/assets/backward-dogs1.png);
      animation: dogAnimation 0.7s steps(2) infinite;
    }
  }
}

@keyframes dogAnimation {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: 120px;
  }
}

@keyframes walkingAnimation {
  from {
    transform: translate(0, 0%);
  }
  to {
    transform: translate(calc(30vw), 0%);
  }
}
@keyframes backwardWalkingAnimation {
  from {
    // transform: translate(100vw, 0%);
    transform: translate(calc(30vw), 0%);
  }
  to {
    transform: translate(0vw, 0%);
  }
}
